.left-navbar {
  position: relative;
  top: 5rem;
  left: 3rem;
}

.group-name {
  font-weight: bold;
  font-size: 1.5rem;
}

.left-navbar-list {
  display: flex;
  flex-direction: column;
}

.side-navbar-item {
  list-style: none;
  position: relative;
  top: 1rem;
  line-height: 3rem;
  font-size: 1.15rem;
}

@media (max-width: 768px) {
  .left-navbar {
    position: relative;
    left: 0.25rem;
  }

  .group-name {
    font-weight: 600;
    font-size: 1rem;
  }
}
