.payment-container {
  width: 90vw;
  margin: auto;
  margin-top: 40px;
}
.payment-container .pay-line {
  width: 100%;
  height: 1px;
  background-color: #a7a7a7;
}
.pay-heading {
  font-size: 24px;
  font-weight: 700;
  padding: 5px 20px;
}
.payment-main {
  display: flex;
  margin-top: 20px;
}
.payement-left {
  flex: 2;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
}
.dropdownlist {
  width: 95%;
  margin: 0px auto 20px auto;
  color: white;
}
.dropdownlist .list {
  background: #003459;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  border-radius: 5px;
}
.dropdownlist .form {
  color: #000;
}
.dropdownlist p .num {
  background-color: #fff;
  margin-right: 5px;
  padding: 3px 10px;
  border-radius: 5px;
  color: #003459;
}
.dropdownlist .address-cont {
  width: 100%;
  display: flex;
  padding: 10px;
  /* border: 2px solid #dbdbdb; */
  flex-direction: column;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 0 0 10px 10px;
  overflow-y: auto;
  max-height: 440px;
  /* align-items: center; */
  padding-inline: 25px;
  background-color: #f1f1f1;
}
.eachAdd input[type="radio"] {
  background: #ffffff;
  outline: none;
  height: 20px;
  width: 20px;
  margin: 5px 10px;
}
.dropdownlist .address-form input:hover {
  outline: none;
  /* border: 2px solid rgba(242, 153, 74, 1); */
  border: 2px solid #002f50;
}
.address-Item p {
  font-size: 14px;
}

.dropdownlist .address-form {
  width: 85%;
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  border: 2px solid #dbdbdb;
  /* border: 2px solid #002f50; */
  border-radius: 0 0 10px 10px;
  border-radius: 18px;
  height: 100%;
  justify-content: center;
}
.dropdownlist .address-form div {
  display: flex;
  width: 100%;
  align-items: center;
}
.dropdownlist .popupBox .address-form {
  border: none;
}
.address-form .input input {
  margin: 10px 10px;
  border-radius: 5px;
  font-size: 13px;
  width: 65% !important;
  padding: 5px;
  align-items: center;
  justify-content: center;
  border: 2px solid #dbdbdb;
  color: #000000;
  /* border: 2px solid #002f50; */
}
.textarea {
  margin: 15px 27px;
  border-radius: 5px;
  font-size: 13px;
  /* width: 65% !important; */
  padding: 5px;
  align-items: center;
  justify-content: center;
  border: 2px solid #dbdbdb;
  color: #000000;
  width: 350px;
}
.textarea:hover {
  border: 2px solid #002f50;
}
.radio {
  margin: 15px 0px;
  border-radius: 5px;
  font-size: 15px;
  /* width: 7% !important; */
  padding: 0px;
  align-items: center;
  justify-content: center;
  /* border: none; */
}
.type {
  color: #000;
}
.type2 {
  color: #000;
}
#country-state {
  margin: 10px 10px;
  border-radius: 5px;
  font-size: 13px;
  width: 65% !important;
  padding: 5px;
  align-items: center;
  justify-content: center;
  border: 2px solid #dbdbdb;

  color: gray;
}
#country-state:hover {
  border: 2px solid #002f50;
}
#country-state option {
  font-size: 13px;
}
.plus-sign {
  font-weight: 600;
}
.address-cont .eachAdd {
  display: flex;
  /* width: 97%; */
  border-radius: 10px;
  padding: 10px;
  margin: 10px 5px;
  color: #000;
  border: 2px solid #dbdbdb;
  align-items: flex-start;
}
.add-Name span {
  color: rgb(55, 165, 208);
  border: 1.5px solid rgb(55, 165, 208);
  padding: 1px 5px 1px 5px;
  font-size: 0.7rem;
  letter-spacing: 0.3px;
  border-radius: 3px;
  margin-left: 8px;
}
.address-Edit-btn {
  /* margin: -3%; */
  margin-top: 5px;
  width: max-content;
  padding: 3px 10px 3px 10px;
  border-radius: 5px;
  outline: none;
  background-color: white;
  border: 1.5px solid gainsboro;
  letter-spacing: 0.3px;
  cursor: pointer;
}
.add-Name {
  display: flex;
}
.add-Name span {
  color: #003459;
  border: 1px solid #003459;
  padding: 1px 5px 1px 5px;
  font-size: 0.7rem;
  letter-spacing: 0.3px;
  border-radius: 3px;
  margin-left: 8px;
}
.remove-Address {
  position: absolute;
  top: 5px;
  right: 8px;
  cursor: pointer;
}
/* ----------------------pop up---------------- */
.address-cont .popupBox {
  width: 38%;
  height: 440px;
  /* margin-top: 15%; */
  background-color: white;
  position: fixed;
  margin-left: -33vw;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.savedAddress_div .popupBox {
  max-width: 650px;
  height: 420px;
  /* margin-top: 15%; */
  background-color: white;
  position: fixed;
  /* margin-left: -33vw; */
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 1560px) {
  .savedAddress_div .popupBox {
    max-width: 550px;
  }
}
@media (max-width: 768px) {
  .address-cont .popupBox {
    width: 90%;
    height: 440px;
    margin-top: 35%;
    background-color: white;
    position: fixed;
    margin-left: 0vw;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dropdownlist .address-form {
    width: 95%;
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: center;
    border: 2px solid #dbdbdb;
    /* border: 2px solid #002f50; */
    border-radius: 0 0 10px 10px;
    border-radius: 18px;
    height: 100%;
    justify-content: center;
  }
}
/* ======================order================ */
.order-summary {
  color: black;
  padding: 10px;
  /* border: 2px solid #dbdbdb; */
  background: #fff;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 0 0 10px 10px;
  max-height: 350px;
  overflow-y: auto;
  background-color: #f1f1f1;
}
.order-summary .item {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: auto;
  border-radius: 10px;
  /* align-items: center; */
  border: 2px solid #dbdbdb;
  padding: 10px 10px;
  margin-bottom: 5px;
}
.order-summary .item img {
  /* width: 120px; */
  margin: 10px;
  margin-right: 20px;
}
.order-summary .product-detail p {
  font-size: 18px;
  font-weight: 400;
  /* font-weight: 600; */
}
/* .order-summary .product-detail .name {
  font-size: 20px;
  font-weight: 600;
} */

.disc span {
  color: #078413;
}

.price span {
  color: #0c64a3;
}
.delivery-add {
  color: #000;
  text-align: left;
  width: 96%;
  /* font-weight: 500; */
}
/* ======================order================ */
.payment-btn-box {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid #dbdbdb; */
  background: #fff;
  /* box-shadow: 0px 4px 4px rgb(0 0 0 / 25%); */
  /* border-radius: 0 0 10px 10px; */
  width: 95%;
  margin: 0px auto 20px auto;
  color: white;
}
.payment-btn {
  width: 200px;
  padding: 5px 0px;
  height: 40px;
  margin: 10px;
  border: none;
  background-color: #003459;
  color: white;
  font-size: 0.9rem;
  letter-spacing: 0.7px;
  border-radius: 4px;
  cursor: pointer;
}
button:disabled {
  opacity: 0.5;
  color: #fff5f5;
  cursor: no-drop;
}
.payement-right {
  flex: 1;
  padding: 10px;
}
.saved-addresses {
  color: #000;
  display: flex;
  align-items: center;
  flex-direction: column;
}
/* -------------------left----------------- */
.payement-left ul li {
  margin: 5px;
}
/* -------------------left----------------- */

/* ------right part------- */
.payement-right {
  border: 2px solid #dbdbdb;
  padding: 20px;
  margin-left: 10px;
  border-radius: 10px;
  height: 400px;
}
.payement-right .product {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payement-right .product p {
  font-size: 16px;
  font-weight: 700;
  padding: 20px;
}
.payement-right .product img {
  width: 95px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
}
.price-detail h1 {
  font-size: 20px;
  font-weight: 700;
  margin: 20px 0px;
}
.price-detail div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* .price-detail div p {
  font-size: 14px;
  padding-bottom: 10px;
} */
#mrp {
  color: #078413;
}
#coupon {
  color: #003459;
}
.price-detail .total-ammount p {
  font-size: 22px;
}
@media screen and (max-width: 800px) {
  .payment-main {
    flex-direction: column;
  }
  .payement-right {
    margin-left: 0;
    margin-top: 10px;
  }
}

.saved-btn1 {
  margin: 10px auto;
  border: none;
  width: 135px !important;
  background: #003459;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 20px;

  font-style: normal;
  font-weight: 600;
  padding: 5px;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}
.applyCoupon {
  width: 70%;
  box-sizing: border-box;
  margin-top: 5px;
  padding: 4px 10px;
  height: 35px;
  outline: none;
  border: 1px solid #aeaeae;
  border-radius: 5px;
  display: inline-block;
}
.hideApplyCoupon {
  display: none;
}

.toast__margin {
  bottom: 4rem !important;
}
