.pdf-popup {
  width: 100%;
  height: 120vh;
  position: absolute;
  top: 0;
  /* bottom: -60px; */
  left: 0;
  right: 0;
  /* background-color: rgba(0, 0, 0, 0.05); */
  z-index: 99;
  display: flex;
  justify-content: center;
  animation: fadeInDown 0.2s ease;
}

.pdf-popupbox {
  width: 1000px;
  height: 105vh;
  margin-top: 12vh;
  background-color: white;
  /* position: fixed; */
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: relative; */
}
.pdf-div {
  width: 90%;
  margin: 30px auto;
  height: 95%;
  overflow-y: auto;
  padding: 15px;
  background: rgba(222, 233, 255, 0.07);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -2px -4px 4px rgba(0, 0, 0, 20%);
}
.pdf {
  /* width: 950px; */
  padding-top: 5px;
}

.pdf-top {
  /* align-items: baseline;
  display: flex;
  justify-content: space-between; */
}
.pdf-top .logo-div {
  /* padding-left: 10px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pdf-top .heading {
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 28px;
  color: #263d35;
  margin-top: 24px;
}
.example-config {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.pdf-btn {
  margin: 10px auto;
  width: 125px !important;
  background: #f2994a;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;

  font-style: normal;
  font-weight: 600;
  padding: 5px;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  border-radius: 24px;
  border: none;
  /* float: right; */
  margin: 8px 0;
  margin-left: 20px;
}
.detail-div p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding-left: 10px;
  line-height: 17px;
  color: #000000;
}
kendo-pdf-document p {
  font-family: "Robot", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* font-size: 10px; */
}
kendo-pdf-document img {
  margin-top: 24px;
  /* width: 40px;
  height: 30px; */
}
kendo-pdf-document h5 {
  /* font-style: normal; */
  font-family: "Roboto", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  padding-left: 8px;
  padding-bottom: 24px;
  color: #000000;
}
.detail-div .comp-name {
  margin: 15px 0;

  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.25px;
}
.pdf-line {
  width: 100%;
  margin: 5px auto;
  height: 1.5px;

  background: #868a91;
}
/* ======================mid================= */
.pdf-mid {
  display: flex;
  justify-content: space-between;
  margin: 15px 1px;
}
.pdf-mid .mid-left {
  display: flex;
  width: 35%;
  padding-left: 10px;
  justify-content: space-between;
}
.pdf-mid .mid-left .edit-btn {
  width: 40px;
  height: 22px;
  background: #ffffff;
  border: 1px solid #b2b6bb;
  border-radius: 10px;

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;

  color: #000;
}
.mid-left .user-detail p {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
.mid-right div {
  display: flex;
  justify-content: space-between;
}
.mid-right p {
  font-weight: 500;
  font-size: 11px;
  /* line-height: 0px; */
  display: flex;
  align-items: center;
}
.mid-right .name {
  font-weight: 700;
  font-size: 12px;
  line-height: 0px;
  color: #121212;
  margin-right: 10px;
}
/* ======================mid================= */
.line {
  height: 2px;
  width: 540px;
  margin: auto;
  background: #868a91;
}

/* ================item-===================== */
table {
  width: 100%;
}
.item-head {
  width: 100%;
  text-align: left;
}
.item1 h1 {
  margin: 5px 0;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #121212;
}
td .item1 p {
  /* width: 170px; */
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #121212;
  opacity: 0.8;
}
th {
  border-bottom: 1.5px solid #868a91;
}
td {
  border-bottom: 1px solid #ddd;
}

.pdf-item .item1 {
  width: 35%;
  padding-left: 10px;
}
.pdf-item .item2 {
  width: 20%;
  /* display: flex  ; */
  /* align-items: center; */
  text-align: center;
}
.item1 p,
.item2 p {
  font-size: 14px;
}
.item2 p {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* ================item-===================== */
.pdf .total {
  margin: 25px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.t-left p {
  font-weight: 800;
  font-size: 12px;
  padding-left: 10px;
  line-height: 18px;
}

.t-right {
  width: 259px;
}
.t-right div {
  display: flex;
  padding: 2px 5px;
  justify-content: space-between;
}
.t-right div p {
  padding-right: 10px;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  align-items: center;
  /* line-height: 5px; */
  color: #121212;
}
.discount {
  margin: 0 !important;
}
.t-right .discount p {
  color: #ff3e3e;
}
.t-total {
  background: #e6e6e6;
  border-radius: 4px;
  width: 97%;
  /* margin-right: 10px; */
}
.t-right .t-total p {
  font-weight: 500;
  font-size: 14px;
  font-family: "Robot", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* margin: 5px; */
  padding: 5px;
  line-height: 14px;
  color: #000000;
}
.terms h6 {
  font-style: normal;
  font-family: "Roboto", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.4px;
  color: #121212;
  opacity: 0.8;
  padding-left: 10px;
}
.terms p {
  padding-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #121212;
  opacity: 0.6;
}
.example-config {
  height: 50px;

  margin-bottom: 5px;
}

/* ====================edit button css========================== */
.edit-popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.411);
  z-index: 99;
  display: flex;
  justify-content: center;
  animation: fadeInDown 0.2s ease;
}

.edit-popupBox {
  width: 500px;
  height: 496px;

  background: #ffffff;
  border-radius: 10px;
  margin-top: 10%;
  position: fixed;
  padding: 25px 30px;
}
/* @media (min-width: 1400px) {
  .edit-popupBox {
    width: 380px;
    height: 360px;

    background: #ffffff;
    border-radius: 10px;
    margin-top: 10px;
    position: fixed;
    padding: 10px;
  }
} */
.edit-box .heading {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding-left: 8px;
  padding-bottom: 10px;

  color: #000000;
}
.edit-box .form p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.edit-box .form input,
.edit-box .form textarea {
  background: #ffffff;
  width: 335px;
  height: 44px;
  border: 1px solid rgba(210, 210, 210, 0.56);
  margin-top: 2px;
}
.edit-box .form textarea {
  height: 84px;
  margin-bottom: 2px;
}
.edit-box .form div {
  margin-bottom: 15px;
}

/* ====================edit button css========================== */
