.home-container {
  background-color: #f6f6f6;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
}

.eWjCzc:hover:enabled,
.eWjCzc:focus:enabled,
.sc-bczRLJ:hover:enabled,
.sc-bczRLJ:focus:enabled {
  background-color: #003459 !important;
}
.App {
  overflow-y: scroll;
  overflow-y: visible;
}
/* testing */
* {
  /* font-family: Inter; */
  font-family: Roboto;
}

a {
  color: rgb(0, 0, 225);
}
a:hover {
  text-decoration: underline;
}
.align__self__stretch {
  margin: 0;
  align-self: stretch;
}
