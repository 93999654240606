@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap"); */

body {
  margin: 0;
  font-family: "Lato", "Roboto", sans-serif;
  overflow-y: visible;
  overflow-y: scroll;
}

.img {
  pointer-events: none;
}

.user_diy_cart_btn{
  margin: 7px;
    border-radius: 5px;
    background-color: #003459;
    width: 100px;
    border: none;
    height: 40px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #ffffff
}


.container {
  border-radius: 5px;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  position: relative;
}

.resizeable {
  position: absolute;
  border: 2px solid #533535;
  width: 100px;
  height: 100px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 15px;
  min-height: 15px;
}

.resizer {
  position: absolute;
  background: black;
}

.resizer-r {
  cursor: col-resize;
  height: 100%;
  right: 0;
  top: 0;
  width: 5px;
}

.resizer-t {
  cursor: row-resize;
  height: 5px;
  left: 0;
  top: 0;
  width: 100%;
}

.resizer-b {
  cursor: row-resize;
  height: 5px;
  left: 0;
  bottom: 0;
  width: 100%;
}

.resizer-l {
  cursor: col-resize;
  height: 100%;
  left: 0;
  top: 0;
  width: 5px;
}
.active{
  color: indigo;
}